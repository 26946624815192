const appendVideo = (videoWrapper) => {
	const videoUrl = videoWrapper.dataset.video;
	const videoId = videoWrapper.dataset.id || '';
	const videoPoster = videoWrapper.dataset.poster || '';
	// const hasTimer = videoWrapper.dataset.timer; // check if has timer
	const isLoop = videoWrapper.dataset.isloop
		? videoWrapper.dataset.isloop
		: 'loop'; // set loop if none was passed

	const video = document.createElement('video');
	video.muted = true;
	video.playsInline = true;
	video.autoplay = false;
	video.loop = isLoop === 'loop';
	video.preload = 'metadata';
	video.poster = videoPoster;
	video.controls = false;
	video.id = videoId;

	// const source = document.createElement('source');
	// source.src = videoUrl;
	// const videoType = videoUrl.split('.').pop();
	// source.type = `video/${videoType}`;

	const videoUrls = videoUrl.split(',');
	videoUrls.forEach((url) => {
		const source = document.createElement('source');
		source.src = url.trim();
		const videoType = url.trim().split('.').pop();
		source.type = `video/${videoType}`;
		video.appendChild(source);
		video.style.opacity = '0';
	});

	videoWrapper.appendChild(video);

	const playPromise = video.play();

	if (playPromise !== undefined) {
		playPromise
			.then(() => {
				// Automatic playback started!
				video.style.opacity = '1';
				// if (hasTimer) {
				// 	getTimer();
				// }
			})
			.catch((error) => {
				video.style.opacity = '1';
				console.log(error);
			});
	}
};

document.addEventListener('DOMContentLoaded', () => {
	const videos = document.querySelectorAll('.video');
	videos.forEach((videoWrapper) => {
		appendVideo(videoWrapper);
	});
});
